<template lang="pug">
  AccountForm(
    v-if="account"
    :account-data="account"
  )
</template>

<script>
  export default {
    components: {
      AccountForm: () => import("./Form")
    },

    computed: {
      account() {
        const { id, first_name, last_name, email, phone_number } = this.$store.state.currentUser

        return {
          id,
          first_name,
          last_name,
          email,
          phone_number,
          password: "",
          password_confirmation: ""
        }
      }
    }
  }
</script>
